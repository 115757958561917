import { notifySentry } from '@/helpers/notifySentry'
import { FiltersResource } from '@/resources/FiltersResource'

export default defineNuxtRouteMiddleware(async to => {
  const runtimeConfig = useRuntimeConfig()
  const filters = useFilters()
  const {$i18n} = useNuxtApp()

  const {data} = await useFetch(() => `locations/filters`, {
    method: 'GET',
    baseURL: runtimeConfig.public.apiBase,
    query: {
      lang: $i18n.locale.value
    },
    transform: data => {
      try {
        return FiltersResource.parse(data)
      } catch (e) { notifySentry(e) }
    }
  })
  if (data.value) {
    filters.value = data.value
  }
})
