import validate from "/var/www/worksnap/nuxt-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/var/www/worksnap/nuxt-frontend/middleware/auth.global.ts";
import filters_45global from "/var/www/worksnap/nuxt-frontend/middleware/filters.global.ts";
export const globalMiddleware = [
  validate,
  auth_45global,
  filters_45global
]
export const namedMiddleware = {
  auth: () => import("/var/www/worksnap/nuxt-frontend/middleware/auth.ts")
}