import { default as _91slug_931zdbWbYtkQMeta } from "/var/www/worksnap/nuxt-frontend/pages/[slug].vue?macro=true";
import { default as _91id_935QvNh7DDPhMeta } from "/var/www/worksnap/nuxt-frontend/pages/admin/bookings/[id].vue?macro=true";
import { default as indexVaZ84KxkPUMeta } from "/var/www/worksnap/nuxt-frontend/pages/admin/bookings/index.vue?macro=true";
import { default as _91slug_9324fqhzU4ClMeta } from "/var/www/worksnap/nuxt-frontend/pages/admin/spaces/[slug].vue?macro=true";
import { default as indexLg9z3u3NnnMeta } from "/var/www/worksnap/nuxt-frontend/pages/admin/spaces/index.vue?macro=true";
import { default as contactbvkDxxKubuMeta } from "/var/www/worksnap/nuxt-frontend/pages/contact.vue?macro=true";
import { default as faqK1JXbXz82gMeta } from "/var/www/worksnap/nuxt-frontend/pages/faq.vue?macro=true";
import { default as indexM8m7ma0u3IMeta } from "/var/www/worksnap/nuxt-frontend/pages/index.vue?macro=true";
import { default as _91slug_93gQxvFk6JMyMeta } from "/var/www/worksnap/nuxt-frontend/pages/news/[slug].vue?macro=true";
import { default as indexpLNBcSUShnMeta } from "/var/www/worksnap/nuxt-frontend/pages/news/index.vue?macro=true";
import { default as _91slug_93v0ZpEWur1HMeta } from "/var/www/worksnap/nuxt-frontend/pages/spaces/[slug].vue?macro=true";
import { default as indexhxDvrueZMfMeta } from "/var/www/worksnap/nuxt-frontend/pages/spaces/index.vue?macro=true";
import { default as mapyyFv4x1csmMeta } from "/var/www/worksnap/nuxt-frontend/pages/spaces/map.vue?macro=true";
export default [
  {
    name: _91slug_931zdbWbYtkQMeta?.name ?? "slug___en",
    path: _91slug_931zdbWbYtkQMeta?.path ?? "/:slug()",
    meta: _91slug_931zdbWbYtkQMeta || {},
    alias: _91slug_931zdbWbYtkQMeta?.alias || [],
    redirect: _91slug_931zdbWbYtkQMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931zdbWbYtkQMeta?.name ?? "slug___nl",
    path: _91slug_931zdbWbYtkQMeta?.path ?? "/nl/:slug()",
    meta: _91slug_931zdbWbYtkQMeta || {},
    alias: _91slug_931zdbWbYtkQMeta?.alias || [],
    redirect: _91slug_931zdbWbYtkQMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931zdbWbYtkQMeta?.name ?? "slug___de",
    path: _91slug_931zdbWbYtkQMeta?.path ?? "/de/:slug()",
    meta: _91slug_931zdbWbYtkQMeta || {},
    alias: _91slug_931zdbWbYtkQMeta?.alias || [],
    redirect: _91slug_931zdbWbYtkQMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_935QvNh7DDPhMeta?.name ?? "admin-bookings-id___en",
    path: _91id_935QvNh7DDPhMeta?.path ?? "/admin/bookings/:id()",
    meta: _91id_935QvNh7DDPhMeta || {},
    alias: _91id_935QvNh7DDPhMeta?.alias || [],
    redirect: _91id_935QvNh7DDPhMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/admin/bookings/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_935QvNh7DDPhMeta?.name ?? "admin-bookings-id___nl",
    path: _91id_935QvNh7DDPhMeta?.path ?? "/nl/admin/bookings/:id()",
    meta: _91id_935QvNh7DDPhMeta || {},
    alias: _91id_935QvNh7DDPhMeta?.alias || [],
    redirect: _91id_935QvNh7DDPhMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/admin/bookings/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_935QvNh7DDPhMeta?.name ?? "admin-bookings-id___de",
    path: _91id_935QvNh7DDPhMeta?.path ?? "/de/admin/bookings/:id()",
    meta: _91id_935QvNh7DDPhMeta || {},
    alias: _91id_935QvNh7DDPhMeta?.alias || [],
    redirect: _91id_935QvNh7DDPhMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/admin/bookings/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVaZ84KxkPUMeta?.name ?? "admin-bookings___en",
    path: indexVaZ84KxkPUMeta?.path ?? "/admin/bookings",
    meta: indexVaZ84KxkPUMeta || {},
    alias: indexVaZ84KxkPUMeta?.alias || [],
    redirect: indexVaZ84KxkPUMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/admin/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: indexVaZ84KxkPUMeta?.name ?? "admin-bookings___nl",
    path: indexVaZ84KxkPUMeta?.path ?? "/nl/admin/bookings",
    meta: indexVaZ84KxkPUMeta || {},
    alias: indexVaZ84KxkPUMeta?.alias || [],
    redirect: indexVaZ84KxkPUMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/admin/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: indexVaZ84KxkPUMeta?.name ?? "admin-bookings___de",
    path: indexVaZ84KxkPUMeta?.path ?? "/de/admin/bookings",
    meta: indexVaZ84KxkPUMeta || {},
    alias: indexVaZ84KxkPUMeta?.alias || [],
    redirect: indexVaZ84KxkPUMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/admin/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9324fqhzU4ClMeta?.name ?? "admin-spaces-slug___en",
    path: _91slug_9324fqhzU4ClMeta?.path ?? "/admin/spaces/:slug()",
    meta: _91slug_9324fqhzU4ClMeta || {},
    alias: _91slug_9324fqhzU4ClMeta?.alias || [],
    redirect: _91slug_9324fqhzU4ClMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/admin/spaces/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9324fqhzU4ClMeta?.name ?? "admin-spaces-slug___nl",
    path: _91slug_9324fqhzU4ClMeta?.path ?? "/nl/admin/spaces/:slug()",
    meta: _91slug_9324fqhzU4ClMeta || {},
    alias: _91slug_9324fqhzU4ClMeta?.alias || [],
    redirect: _91slug_9324fqhzU4ClMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/admin/spaces/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9324fqhzU4ClMeta?.name ?? "admin-spaces-slug___de",
    path: _91slug_9324fqhzU4ClMeta?.path ?? "/de/admin/spaces/:slug()",
    meta: _91slug_9324fqhzU4ClMeta || {},
    alias: _91slug_9324fqhzU4ClMeta?.alias || [],
    redirect: _91slug_9324fqhzU4ClMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/admin/spaces/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexLg9z3u3NnnMeta?.name ?? "admin-spaces___en",
    path: indexLg9z3u3NnnMeta?.path ?? "/admin/spaces",
    meta: indexLg9z3u3NnnMeta || {},
    alias: indexLg9z3u3NnnMeta?.alias || [],
    redirect: indexLg9z3u3NnnMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/admin/spaces/index.vue").then(m => m.default || m)
  },
  {
    name: indexLg9z3u3NnnMeta?.name ?? "admin-spaces___nl",
    path: indexLg9z3u3NnnMeta?.path ?? "/nl/admin/spaces",
    meta: indexLg9z3u3NnnMeta || {},
    alias: indexLg9z3u3NnnMeta?.alias || [],
    redirect: indexLg9z3u3NnnMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/admin/spaces/index.vue").then(m => m.default || m)
  },
  {
    name: indexLg9z3u3NnnMeta?.name ?? "admin-spaces___de",
    path: indexLg9z3u3NnnMeta?.path ?? "/de/admin/spaces",
    meta: indexLg9z3u3NnnMeta || {},
    alias: indexLg9z3u3NnnMeta?.alias || [],
    redirect: indexLg9z3u3NnnMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/admin/spaces/index.vue").then(m => m.default || m)
  },
  {
    name: contactbvkDxxKubuMeta?.name ?? "contact___en",
    path: contactbvkDxxKubuMeta?.path ?? "/contact",
    meta: contactbvkDxxKubuMeta || {},
    alias: contactbvkDxxKubuMeta?.alias || [],
    redirect: contactbvkDxxKubuMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactbvkDxxKubuMeta?.name ?? "contact___nl",
    path: contactbvkDxxKubuMeta?.path ?? "/nl/contact",
    meta: contactbvkDxxKubuMeta || {},
    alias: contactbvkDxxKubuMeta?.alias || [],
    redirect: contactbvkDxxKubuMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactbvkDxxKubuMeta?.name ?? "contact___de",
    path: contactbvkDxxKubuMeta?.path ?? "/de/contact",
    meta: contactbvkDxxKubuMeta || {},
    alias: contactbvkDxxKubuMeta?.alias || [],
    redirect: contactbvkDxxKubuMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faqK1JXbXz82gMeta?.name ?? "faq___en",
    path: faqK1JXbXz82gMeta?.path ?? "/faq",
    meta: faqK1JXbXz82gMeta || {},
    alias: faqK1JXbXz82gMeta?.alias || [],
    redirect: faqK1JXbXz82gMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqK1JXbXz82gMeta?.name ?? "faq___nl",
    path: faqK1JXbXz82gMeta?.path ?? "/nl/faq",
    meta: faqK1JXbXz82gMeta || {},
    alias: faqK1JXbXz82gMeta?.alias || [],
    redirect: faqK1JXbXz82gMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqK1JXbXz82gMeta?.name ?? "faq___de",
    path: faqK1JXbXz82gMeta?.path ?? "/de/faq",
    meta: faqK1JXbXz82gMeta || {},
    alias: faqK1JXbXz82gMeta?.alias || [],
    redirect: faqK1JXbXz82gMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexM8m7ma0u3IMeta?.name ?? "index___en",
    path: indexM8m7ma0u3IMeta?.path ?? "/",
    meta: indexM8m7ma0u3IMeta || {},
    alias: indexM8m7ma0u3IMeta?.alias || [],
    redirect: indexM8m7ma0u3IMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexM8m7ma0u3IMeta?.name ?? "index___nl",
    path: indexM8m7ma0u3IMeta?.path ?? "/nl",
    meta: indexM8m7ma0u3IMeta || {},
    alias: indexM8m7ma0u3IMeta?.alias || [],
    redirect: indexM8m7ma0u3IMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexM8m7ma0u3IMeta?.name ?? "index___de",
    path: indexM8m7ma0u3IMeta?.path ?? "/de",
    meta: indexM8m7ma0u3IMeta || {},
    alias: indexM8m7ma0u3IMeta?.alias || [],
    redirect: indexM8m7ma0u3IMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93gQxvFk6JMyMeta?.name ?? "news-slug___en",
    path: _91slug_93gQxvFk6JMyMeta?.path ?? "/news/:slug()",
    meta: _91slug_93gQxvFk6JMyMeta || {},
    alias: _91slug_93gQxvFk6JMyMeta?.alias || [],
    redirect: _91slug_93gQxvFk6JMyMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93gQxvFk6JMyMeta?.name ?? "news-slug___nl",
    path: _91slug_93gQxvFk6JMyMeta?.path ?? "/nl/news/:slug()",
    meta: _91slug_93gQxvFk6JMyMeta || {},
    alias: _91slug_93gQxvFk6JMyMeta?.alias || [],
    redirect: _91slug_93gQxvFk6JMyMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93gQxvFk6JMyMeta?.name ?? "news-slug___de",
    path: _91slug_93gQxvFk6JMyMeta?.path ?? "/de/news/:slug()",
    meta: _91slug_93gQxvFk6JMyMeta || {},
    alias: _91slug_93gQxvFk6JMyMeta?.alias || [],
    redirect: _91slug_93gQxvFk6JMyMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexpLNBcSUShnMeta?.name ?? "news___en",
    path: indexpLNBcSUShnMeta?.path ?? "/news",
    meta: indexpLNBcSUShnMeta || {},
    alias: indexpLNBcSUShnMeta?.alias || [],
    redirect: indexpLNBcSUShnMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexpLNBcSUShnMeta?.name ?? "news___nl",
    path: indexpLNBcSUShnMeta?.path ?? "/nl/news",
    meta: indexpLNBcSUShnMeta || {},
    alias: indexpLNBcSUShnMeta?.alias || [],
    redirect: indexpLNBcSUShnMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexpLNBcSUShnMeta?.name ?? "news___de",
    path: indexpLNBcSUShnMeta?.path ?? "/de/news",
    meta: indexpLNBcSUShnMeta || {},
    alias: indexpLNBcSUShnMeta?.alias || [],
    redirect: indexpLNBcSUShnMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93v0ZpEWur1HMeta?.name ?? "spaces-slug___en",
    path: _91slug_93v0ZpEWur1HMeta?.path ?? "/spaces/:slug()",
    meta: _91slug_93v0ZpEWur1HMeta || {},
    alias: _91slug_93v0ZpEWur1HMeta?.alias || [],
    redirect: _91slug_93v0ZpEWur1HMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/spaces/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93v0ZpEWur1HMeta?.name ?? "spaces-slug___nl",
    path: _91slug_93v0ZpEWur1HMeta?.path ?? "/nl/spaces/:slug()",
    meta: _91slug_93v0ZpEWur1HMeta || {},
    alias: _91slug_93v0ZpEWur1HMeta?.alias || [],
    redirect: _91slug_93v0ZpEWur1HMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/spaces/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93v0ZpEWur1HMeta?.name ?? "spaces-slug___de",
    path: _91slug_93v0ZpEWur1HMeta?.path ?? "/de/spaces/:slug()",
    meta: _91slug_93v0ZpEWur1HMeta || {},
    alias: _91slug_93v0ZpEWur1HMeta?.alias || [],
    redirect: _91slug_93v0ZpEWur1HMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/spaces/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexhxDvrueZMfMeta?.name ?? "spaces___en",
    path: indexhxDvrueZMfMeta?.path ?? "/spaces",
    meta: indexhxDvrueZMfMeta || {},
    alias: indexhxDvrueZMfMeta?.alias || [],
    redirect: indexhxDvrueZMfMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/spaces/index.vue").then(m => m.default || m)
  },
  {
    name: indexhxDvrueZMfMeta?.name ?? "spaces___nl",
    path: indexhxDvrueZMfMeta?.path ?? "/nl/spaces",
    meta: indexhxDvrueZMfMeta || {},
    alias: indexhxDvrueZMfMeta?.alias || [],
    redirect: indexhxDvrueZMfMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/spaces/index.vue").then(m => m.default || m)
  },
  {
    name: indexhxDvrueZMfMeta?.name ?? "spaces___de",
    path: indexhxDvrueZMfMeta?.path ?? "/de/spaces",
    meta: indexhxDvrueZMfMeta || {},
    alias: indexhxDvrueZMfMeta?.alias || [],
    redirect: indexhxDvrueZMfMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/spaces/index.vue").then(m => m.default || m)
  },
  {
    name: mapyyFv4x1csmMeta?.name ?? "spaces-map___en",
    path: mapyyFv4x1csmMeta?.path ?? "/spaces/map",
    meta: mapyyFv4x1csmMeta || {},
    alias: mapyyFv4x1csmMeta?.alias || [],
    redirect: mapyyFv4x1csmMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/spaces/map.vue").then(m => m.default || m)
  },
  {
    name: mapyyFv4x1csmMeta?.name ?? "spaces-map___nl",
    path: mapyyFv4x1csmMeta?.path ?? "/nl/spaces/map",
    meta: mapyyFv4x1csmMeta || {},
    alias: mapyyFv4x1csmMeta?.alias || [],
    redirect: mapyyFv4x1csmMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/spaces/map.vue").then(m => m.default || m)
  },
  {
    name: mapyyFv4x1csmMeta?.name ?? "spaces-map___de",
    path: mapyyFv4x1csmMeta?.path ?? "/de/spaces/map",
    meta: mapyyFv4x1csmMeta || {},
    alias: mapyyFv4x1csmMeta?.alias || [],
    redirect: mapyyFv4x1csmMeta?.redirect || undefined,
    component: () => import("/var/www/worksnap/nuxt-frontend/pages/spaces/map.vue").then(m => m.default || m)
  }
]