import { z } from 'zod';

export const AuthUserResource = z.object({
  id: z.number(),
  email: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  phone: z.string(),
  image: z.string().nullable(),
  company: z.string().nullable(),
  vat: z.string().nullable(),
  is_location_admin: z.boolean().nullable().default(false),
  roles: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      slug: z.string(),
    })
  ),
})

export type AuthUserResource = z.infer<typeof AuthUserResource>