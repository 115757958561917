import {z} from 'zod'
import {DetailResponse} from '@eli5/js-helpers'
import {useAuthStore as AuthStore, setApiUrl} from '@eli5/vue-auth'
import {AuthUserResource} from '@/resources/AuthUserResource'

if (process.client) setApiUrl('/api/web')
else setApiUrl(process.env.VITE_API_URL ?? '')

export const AuthUserDetailResponse = DetailResponse.extend({
  data: AuthUserResource,
})

export type AuthUserDetailResponse = z.infer<typeof AuthUserDetailResponse>

export const useAuthStore = AuthStore<AuthUserDetailResponse>({typeGuard: AuthUserDetailResponse})
