<script setup lang="ts">
import '@/assets/styles/index.postcss'

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true
})

useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
    dir: i18nHead.value.htmlAttrs!.dir,
  },
  script: [
    {
      src: 'https://www.googletagmanager.com/gtag/js?id=G-G42K1JK29X',
      async: true,
    },
    {
      innerHTML: "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-G42K1JK29X');"
    },
    {
      innerHTML: "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WJBRWXN3');"
    }
  ],
  noscript: [
    {
      innerHTML: '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WJBRWXN3" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
    }
  ]
})
useSchemaOrg([
  defineOrganization({
    name: 'WorkSnap',
    logo: '/ws-logo.png',
    sameAs: [
      'https://www.facebook.com/profile.php?id=100085010487813',
      'https://www.instagram.com/worksnap_meetsnap/'
    ]
  }),
  defineWebSite(),
  defineWebPage(),
])
</script>

<template>
  <NuxtLayout>
    <SeoKit :language="i18nHead.htmlAttrs!.lang"/>
    <OgImageStatic/>
    <NuxtPage/>
  </NuxtLayout>
</template>