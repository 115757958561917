import { z } from 'zod';

export const LocationTypeResource = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
  description: z.string(),
  icon: z.null(),
})

export type LocationTypeResource = z.infer<typeof LocationTypeResource>