import {useAuthService} from '@eli5/vue-auth'
import {AuthUserDetailResponse, useAuthStore} from '@/store/useAuthStore'

export default defineNuxtRouteMiddleware(async to => {
  if (!process.client) return

  const headers = useRequestHeaders(['cookie']) as HeadersInit
  try {
    const store = useAuthStore()
    const {getMe} = useAuthService<AuthUserDetailResponse>({typeGuard: AuthUserDetailResponse})
    const data = await getMe({headers})
    store.set(data)
  } catch (e) {
    //
  }
})
