import { HTTPError } from 'ky'
import * as Sentry from '@sentry/vue'

export const notifySentry = (e: unknown) => {
  // When api error, do not report on authentication errors.
  if (typeof e === 'object' && e !== null && 'name' in e && e.name === 'HTTPError') {
    const error = e as HTTPError
    if (error.response.status >= 500 || error.response.status < 400) report(e)
  }
  else report(e)
}

const report = (e: unknown) => {
  if (e instanceof Error) Sentry.captureException(e)
}

