export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon-16x16.png","sizes":"16x16"},{"rel":"icon","type":"image/png","href":"/favicon-32x32.png","sizes":"32x32"},{"rel":"icon","type":"image/png","href":"/android-chrome-192x192.png","sizes":"192x192"},{"rel":"apple-touch-icon","type":"image/x-icon","href":"/apple-touch-icon.png","sizes":"180x180"},{"rel":"manifest","href":"/site.webmanifest"}],"style":["https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.4/tiny-slider.css"],"script":[{"src":"https://maps.googleapis.com/maps/api/js?key=AIzaSyCDo0l7KyQTZ3XyJenaC6YRlPvoWdOjk6Y&libraries=places,maps,marker&callback=Function.prototype","defer":true},{"src":"https://kit.fontawesome.com/c04710793d.js","crossorigin":"anonymous"}],"noscript":[],"titleTemplate":"%s %separator %siteName"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = true

export const devPagesDir = null

export const devRootDir = null