import {z} from 'zod'
import {AmenityResource} from './AmenityResource'
import {LocationTypeResource} from './LocationTypeResource'

export const FiltersResource = z.object({
  availableDates: z.array(z.string()),
  locationTypes: z.array(LocationTypeResource),
  amenities: z.array(AmenityResource),
})

export type FiltersResource = z.infer<typeof FiltersResource>
