import revive_payload_client_4sVQNw7RlN from "/var/www/worksnap/nuxt-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/var/www/worksnap/nuxt-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import siteConfig_Eeyr9ytLpT from "/var/www/worksnap/nuxt-frontend/node_modules/nuxt-site-config/dist/runtime/plugins/siteConfig.mjs";
import plugin_vue3_A0OWXRrUgq from "/var/www/worksnap/nuxt-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/worksnap/nuxt-frontend/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/var/www/worksnap/nuxt-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/var/www/worksnap/nuxt-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BEnDUEy4ze from "/var/www/worksnap/nuxt-frontend/node_modules/nuxt-unhead/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/var/www/worksnap/nuxt-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/var/www/worksnap/nuxt-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/worksnap/nuxt-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_3AyO8nEfhE from "/var/www/worksnap/nuxt-frontend/plugins/sentry.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  siteConfig_Eeyr9ytLpT,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_BEnDUEy4ze,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  sentry_3AyO8nEfhE
]