import * as Sentry from '@sentry/vue'

function getSentryIntegrations() {
  // don't load on server
  if (!process.client) return []

  const router = useRouter()
  const browserTracing = new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  })

  return [browserTracing]
}

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp
    const config = useRuntimeConfig()
    Sentry.init({
      app: vueApp,
      dsn: config.public.SENTRY_DSN_PUBLIC as string ?? undefined,
      integrations: getSentryIntegrations(),

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE as number,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    })
  }
})