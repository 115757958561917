import { z } from 'zod';

export const AmenityResource = z.object({
  id: z.number(),
  name: z.string().optional(),
  slug: z.string().optional(),
  icon: z.string(),
  description: z.string(),
})

export type AmenityResource = z.infer<typeof AmenityResource> 